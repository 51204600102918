import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import CloudUpload from '../../components/button/Cloudupload2';
import {Grid} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { getclass } from '../../slices/class';
import EditIcon from "@mui/icons-material/Edit";
import { updateidstudent } from '../../slices/idcard';
import Camera from '../../components/button/Camera';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  boxShadow: 12,
  maxHeight:'100%',
  overflowY: 'scroll',
  p: 4,
};

export default function Editstudent({setLoader, editData}) {

  const [open, setOpen] = React.useState(false);

  const [file, setFile] = React.useState(null);

  const [formdata, setFormdata] = React.useState({
    name:"",
    img:"",
    dob:"",
    std:"",
    fathername:"",
    address1:"",
    address2:"",
    address3:"",
    vanno:"",
    phonenumber:"",
    bloodgroup:"",
});

  const dispatch = useDispatch();

  const handleValue = (e, name) => {
    setFormdata({
      ...formdata,
      [name]: e.target.value,
    });
  }

  React.useEffect(()=>{
    setLoader(true);
    setFormdata(editData);
    setFile(editData?.img ? editData?.img : null);
    dispatch(getclass())
    .then(()=>{
      setLoader(false);
    })
    .catch(()=>{
      setLoader(false);
    })
  },[])

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formdata.name || !formdata.phonenumber || !formdata.address1 || !formdata.address2 || !formdata.address3) {
      window.alert('Please fill out all required fields.');
    } else {
      setLoader(true);
      const c_id = editData._id
      const formData = {...formdata, img: file}
      dispatch(updateidstudent({c_id, formData}))
      .then(() => {
        setLoader(false);
        setOpen(false);
      })
      .catch(()=>{
        setLoader(false);
      })
    }

  };

  return (
    <div>
 <Button
                                        onClick={handleOpen}
                                          sx={{
                                            background: "#3d07dc",
                                            marginRight: 2,
                                            height: '40px',
                                            width:'40px',
                                            minWidth: 0
                                          }}
                                        >
                                          <EditIcon
                                            sx={{ color: "#fff", fontSize: 17 }}
                                          ></EditIcon>
                                        </Button>      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Grid container sx={{paddingBottom: 2}}>
                <Grid xs={6} sx={{textAlign:'start'}}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
            Update Student
          </Typography>
                </Grid>
                <Grid xs={6} sx={{textAlign:'end'}}>
               <CloseIcon onClick={handleClose} sx={{cursor:'pointer'}}></CloseIcon>
                </Grid>
            </Grid>
            <hr></hr>
         <Box
          style={{marginTop: '30px'}}
          component="form"
      noValidate
      autoComplete="off"
          >
          <Grid container spacing={2}>
            <Grid item xs={6}>
            <Box sx={{marginBottom: 3}}>
            <CloudUpload file={file} setFile={setFile} setLoader={setLoader} placeholder="Upload Image"/>
            </Box>
            <TextField required sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="Name" variant="outlined" 
          value={formdata.name}
          onChange={(e)=>handleValue(e, 'name')}
         />
                           <TextField required sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="Date of Birth" variant="outlined" 
          value={formdata.dob}
            onChange={(e)=>handleValue(e, 'dob')}
         />
                                    <TextField required sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="STD" variant="outlined" 
          value={formdata.std}
            onChange={(e)=>handleValue(e, 'std')}
         />
                                             <TextField required sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="Father Name" variant="outlined" 
          value={formdata.fathername}
            onChange={(e)=>handleValue(e, 'fathername')}
         />
          <TextField required sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="Phone Number" variant="outlined" 
          value={formdata.phonenumber}
            onChange={(e)=>handleValue(e, 'phonenumber')}
         />
            </Grid>
            <Grid item xs={6}>
            <Box sx={{marginBottom: 3, border:'1px solid green', padding: 2}}>
            <Camera setFile={setFile} setLoader={setLoader}/>
            </Box>
            <TextField required sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="Address1" variant="outlined" 
          value={formdata.address1}
    onChange={(e)=>handleValue(e, 'address1')}
         />
          <TextField required sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="Address2" variant="outlined" 
          value={formdata.address2}
    onChange={(e)=>handleValue(e, 'address2')}
         />
          <TextField required sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="Address3" variant="outlined" 
          value={formdata.address3}
    onChange={(e)=>handleValue(e, 'address3')}
         />
                  <TextField required sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="Van No" variant="outlined" 
          value={formdata.vanno}
    onChange={(e)=>handleValue(e, 'vanno')}
         />
                                    <TextField required sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="Blood Group" variant="outlined" 
          value={formdata.bloodgroup}
            onChange={(e)=>handleValue(e, 'bloodgroup')}
         />
            </Grid>
          </Grid>
         <Button onClick={handleSubmit} type='submit' size='large' sx={{marginTop: 2, width: '100%'}} variant='contained'>Update</Button>
         </Box>
        </Box>
      </Modal>
    </div>
  );
}